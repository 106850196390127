@import (reference) "variables.less";
@import (reference) "mixins.less";

/****************************
*   BASE STYLES 
*****************************/
*, *:before, *:after { .box-sizing(border-box); }
html, body { position:relative; width: 100%; min-height: 100%; height: 100%; margin: 0; padding: 0; overflow-x:hidden; vertical-align:baseline;}
html { height: 100%; font-size: 16px; 
    @media @mq-xlarge { font-size: (16px * 1.20);}
    @media @mq-huge { font-size: (16px * 1.60);}
    @media @mq-whynot { font-size: (16px * 1.90);}
}
body { font-family: @font-primary; line-height: @line-height; color: @default-font-color; background:#0F0F0F url("/images/bg.png"); font-size: @body-font-size;}

a { color: #DDD; text-decoration: none; font-size: 0.8125rem; .transition();
    &:hover {color: @primary-color;}
}

::selection { background: @primary-color-lighter; color: contrast(@primary-color-lighter);}
::-moz-selection { background: @primary-color-lighter; color: contrast(@primary-color-lighter);}

/****************************
*   HELPERS & QUICK STYLES
*****************************/
.clearfix { &:after { content: " "; display: block; clear: both; } }

.screen-reader-only {
    position:absolute; 
    left:-10000px; 
    top:auto; 
    width:1px; 
    height:1px; 
    overflow:hidden; 
}

/****************************
*   COMMON
*****************************/
.content-wrapper {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.content-center {
    display: table-cell;
    vertical-align: middle;
}

.content {
    text-align: left;
    max-width: 16.5rem;
    margin: auto;
    text-shadow: 0 0.125rem 0.3125rem #000;
    overflow: visible;
    white-space: nowrap;

    @media @mq-small {
        margin-top: -5rem;
    }
}

h1 {
    font-family: @font-secondary;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #DDD;
    line-height: 1.2;

    span {
        display: block;
        font-size: 1rem;
        font-weight: 600;
        text-transform: none;
        color: @primary-color;
        text-indent: 0.125rem;
    }
}

.separator {
    display: inline-block;
    vertical-align: middle;
    color: @primary-color-darker;
    letter-spacing: -0.125rem;
    padding: 0 0.25rem;
}