﻿/****************************
*   COLORS
*****************************/
@default-font-color: #eee;

@primary-color: #D7BA7F;;
@primary-color-lighter: lighten(spin(@primary-color, 5), 10%);
@primary-color-lightest: lighten(spin(@primary-color, 10), 20%);
@primary-color-darker: darken(spin(@primary-color, -5), 10%);
@primary-color-darkest: darken(spin(@primary-color, -10), 20%);

@secondary-color: #A6541E;
@secondary-color-lighter: lighten(spin(@secondary-color, 5), 10%);
@secondary-color-lightest: lighten(spin(@secondary-color, 10), 20%);
@secondary-color-darker: darken(spin(@secondary-color, -5), 10%);
@secondary-color-darkest: darken(spin(@secondary-color, -10), 20%);

/****************************
*   LAYOUT
*****************************/
/* Page Sizing */
@max-content-width: 85rem;
@gutter: 0.9375rem;


/****************************
*   TYPOGRAPHY
*****************************/
@body-font-size: 100%; /* A percentage of the root (html) font size - 16px by default */
@font-primary: 'Open Sans', sans-serif;
@font-secondary: 'Jura', sans-serif;
@line-height: 1.5;


/****************************
*   BREAKPOINTS
*
*   Breakpoints "large" and above are used primarily for 
*   document font size changes. Layout remains the same as
*   element sizes are tied to the document font size.
*
*****************************/
@bp-small: 46em; /* 768px - TABLET LAYOUT START (APPROX) */
@bp-medium: 64em; /* 1024px - DESKTOP LAYOUT START (APPROX) */
@bp-large: 85.375em; /* 1366px */
@bp-xlarge: 122.5em; /* 1960px */
@bp-huge: 160em; /* 2560px */
@bp-whynot: 200em; /* 3200px */


/****************************
*   MEDIA QUERIES
*****************************/
@mq-small: ~"screen and (min-width: @{bp-small})";
@mq-medium: ~"screen and (min-width: @{bp-medium})";
@mq-large: ~"screen and (min-width: @{bp-large})";
@mq-xlarge: ~"screen and (min-width: @{bp-xlarge})";
@mq-huge: ~"screen and (min-width: @{bp-huge})";
@mq-whynot: ~"screen and (min-width: @{bp-whynot})";
@mq-retina: ~"screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 192dpi)";